<template>
  <v-container fluid>
    <!-- add dropzone to upload and excel file -->
    <v-card class="p-0">
      <v-card-title class="card-header">
        <h3 class="headline mb-0">{{ $t('erp.lang_exportAllItems') }}</h3>
        <v-spacer></v-spacer>
        <v-btn elevation="0" color="primary" @click="downloadItemsExport" :loading="loading">
          <span class="text-white ">{{ $t('generic.lang_downloadExcel') }}</span>
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { ENDPOINTS } from '@/config';
import { Events } from '@/plugins/events';
export default {
  name: 'ItemExportComponent',
  data() {
    return {
      ENDPOINTS,  
      loading: false
    };
  },
  computed: {
    ...mapState([
        'api'
    ])
  },
  methods: {
    downloadItemsExport() {
      this.loading = true;
      let apiServerIP = process.env.VUE_APP_API_HOST;
      if(!window.location.host.includes('localhost') && window.location.host != 'devkasse.3pos.de' && window.location.host != 'kasse.3pos.de'){
        apiServerIP = location.protocol + "//" + window.location.hostname + ":8010/pos";
      }
      window.open(apiServerIP + "/get/erp/itemExport/?bs=" + this.api.auth.posID + "&bsid=" + this.api.auth.accessString + "&apiToken=" + this.api.auth.apiWebToken, "_blank");
      this.loading = false;
    },
    exportItems(){
      this.loading = true;
      this.axios.get(ENDPOINTS.ERP.ITEM.EXPORT.EXCEL, {},
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //DOWNLOAD FILE AS EXCEL
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }), "Items export.xlsx");
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
  },
};
</script>
