<template>
  <div>
    <page-title
      :heading="$t('erp.lang_wareExport')"
      :subheading="$t('erp.lang_wareExport')"
      :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <item-export-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../Layout/Components/PageTitle.vue';
import ItemExportComponent from '../../../components/erp/settings/ItemExportComponent.vue';

export default {
  components: {
    PageTitle,
    ItemExportComponent
  },
  data: () => ({
    icon: 'pe-7s-upload icon-gradient bg-tempting-azure',
  }),
};
</script>
